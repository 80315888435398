import { useEffect, useRef } from 'react';
import { PagePresenceCallback } from '@wf-mfe/event-stream/types/page-presence-callback';
import { closeConnection, getConnection } from '@wf-mfe/event-stream';
import { RtbeConnection } from '@wf-mfe/event-stream/rtbe-connection';
import { ComponentPresenceCallback } from '@wf-mfe/event-stream/types/component-presence-callback';
import { useSelector } from '@store';

import { useRecordTypeData } from '../useRecordTypeData/useRecordTypeData';

export const useGetPresenceData = () => useSelector((store) => store.presenceRTBE);

export const usePresenceConnection = () => {
  const { current } = useRef<RtbeConnection<unknown>>(
    getConnection(
      'maestro',
      'maestro-record-type',
      () => void 0,
      () => void 0
    )
  );

  useEffect(
    () => () => {
      closeConnection(current);
    },
    [current]
  );

  return current;
};

export const usePagePresence = (
  rtbeConnection: RtbeConnection<unknown>,
  presenceCallback?: PagePresenceCallback
) => {
  const { recordType } = useRecordTypeData();

  useEffect(() => {
    if (!recordType?.id || !rtbeConnection) {
      return;
    }

    if (presenceCallback) {
      rtbeConnection.stopPagePresence();
      rtbeConnection.startPagePresence(recordType.id, presenceCallback);
    }
  }, [presenceCallback, recordType.id, rtbeConnection]);
};

export const useComponentPresence = (
  rtbeConnection: RtbeConnection<unknown>,
  presenceCallback: ComponentPresenceCallback
) => {
  const { recordType } = useRecordTypeData();

  useEffect(() => {
    if (!recordType?.id || !rtbeConnection) {
      return;
    }

    if (presenceCallback) {
      rtbeConnection.startComponentPresence(recordType.id, presenceCallback);
    }
  }, [presenceCallback, recordType.id, rtbeConnection]);
};
